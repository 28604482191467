.cheap-route {
    margin: 30px 0;

    .ant-card-head {
        background-color: #4ec7bb;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }

    .ant-card-body {
        height: 300px;
        overflow: auto;
    }
}