.footer {
    /*position: fixed; 浮动*/
    position: static;
    left: 0;
    bottom: 0;
    /* width: 100%; */
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    padding: 10px;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.footer a {
    color: #007bff;
}