.my-select {
    .ant-select-selector {
        border: 2px solid #ccc !important;
    }

    .ant-select-selector:hover {
        border: 2px solid #00bc58 !important;
    }
}

.my-picker {
    .ant-picker-outlined {
        border: 2px solid #ccc !important;
    }

    .ant-picker-outlined:hover {
        border: 2px solid #4070ff !important;
    }
}